.command_tunnel--container {
  @apply h-screen flex flex-row;
}

.command_tunnel--map {
  position: fixed;
  top: 100px;
  height: calc(100vh - 100px);
  @apply w-2/3;
}
.command_tunnel--map_container {
  @apply h-full;
}

.command_tunnel--form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
