@import "../stylesheets/pages.css";
@import "../stylesheets/commandTunnelLayout.css";

.leaflet-control-layers-toggle:after {
  content: "Fond de carte";
  color: #000;
  padding: 0 0 0 4rem;
  font-weight: bold;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 200px !important;
  display: inline-flex;
  align-items: center;
  padding: 3px;
  text-decoration: none;
  line-height: 36px;
}

.leaflet-control-layers-list {
  width: 200px !important;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-size: 26px 26px;
  background-position: 10%;
  justify-content: flex-start;
}

*:focus {
  outline: none;
}
