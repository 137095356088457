@import "../stylesheets/app.css";
@import "rc-slider/assets/index.css";
/* fonts */
@import "https://fonts.googleapis.com/css2?family=Paytone+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-popup-content {
  margin: 16px !important;
  margin-top: 24px !important;
  display: flex !important;
}

input[type="text"]:focus {
  box-shadow: 0 0 0 rgb(255, 255, 255);
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 10% 50% !important;
}

body.waiting * {
  cursor: wait !important;
}
