.footer-form-commande-data-container {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 16px;
  column-gap: 24px;
  padding: 24px 24px;
}

.footer-form-commande-data-container > div {
  min-height: 72px;
  -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  @apply flex items-center justify-center bg-white rounded-md;
}

.footer-form-commande-placehoder div {
  -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  @apply rounded-md p-6;
}

.footer-form-commande-placehoder div span:first-child {
  display: block;
  height: 21px;
  width: 101px;
  @apply bg-gray-200 rounded-md mb-2;
}

.footer-form-commande-placehoder div span:last-child {
  display: block;
  height: 8px;
  max-width: 125px;
  width: 100%;
  @apply bg-gray-200 rounded-md;
}

.footer-form-commande-placehoder {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 16px;
  column-gap: 24px;
  padding: 64px 24px 24px 24px;
}

.footer-form-commande-data-container > div > div {
  @apply flex flex-col;
}

.footer-form-commande {
  -webkit-box-shadow: 0px -26px 13px -4px rgba(250, 250, 250, 1);
  -moz-box-shadow: 0px -26px 13px -4px rgba(250, 250, 250, 1);
  box-shadow: 0px -26px 13px -4px rgba(250, 250, 250, 1);
}

.btn-footer-formulaire-commande {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  -webkit-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 10%);
}
