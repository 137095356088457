.commune_item_checkox {
  width: 16px !important;
  height: 16px !important;
  @apply border border-danger rounded relative;
}

.commune_item_checkox span {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply bg-danger rounded-sm;
}

.commune_item {
  height: 36px;
}
