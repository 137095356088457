.recap-container .footer-form-commande-data-container {
  padding: 24px 0px !important;
}

.recap-container .footer-form-commande {
  overflow: visible !important;
}

.definition-recap > div {
  padding: 12px 0px;
  @apply flex justify-between border-t font-poppins font-medium;
}

.footer-recap {
  -webkit-box-shadow: -1px -17px 15px -3px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -1px -17px 15px -3px rgba(0, 0, 0, 0.09);
  box-shadow: -1px -17px 15px -3px rgba(0, 0, 0, 0.09);
}

.my-recap {
  -webkit-box-shadow: 16px -1px 15px -3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 16px -1px 15px -3px rgba(0, 0, 0, 0.05);
  box-shadow: 16px -1px 15px -3px rgba(0, 0, 0, 0.05);
}

.recap-info-user-form-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.recap-checkbox label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 40px;
  cursor: pointer;
  margin-top: 2px;
  @apply font-poppins font-medium text-sm;
}

.recap-checkbox input {
  display: none;
}

.recap-checkbox label::after {
  content: "";
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1;
  border: 2px solid;
  @apply border-gray-200 rounded-md bg-white;
}

.recap-checkbox label::before {
  content: "";
  display: none;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  @apply bg-danger rounded;
}

.recap-checkbox input:checked + label::before {
  display: block;
}
