.shape-input-container div {
  @apply bg-white hover:bg-gray-100 rounded-md p-4 shadow-lg mr-4 cursor-pointer;
}

.my-file-input-label {
  @apply font-medium text-danger hover:font-bold cursor-pointer text-sm;
}

.list-zone-person-container {
  height: 160px;
  padding-right: 24px;
  overflow-y: scroll;
}
